@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}


article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
* { box-sizing: border-box; }



.ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 30px;
  line-height: 30px;
  margin-bottom: 4px;
}

.ant-table, .ant-steps, .ant-form-item, .ant-dropdown, .ant-card, .ant-modal, .ant-message, .ant-select, .ant-calendar {
  /*font-family: 'Balsamiq Sans', cursive;*/
  font-family: 'Roboto', sans-serif;
}

input, select { cursor: pointer; }
form label, form input { cursor: pointer; }

body { scroll-behavior: smooth; }
body { overflow-x: hidden; }

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.18);
  background-color: #F1F1F1;
}
body::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}
body::-webkit-scrollbar-thumb {
  background-color: #C1C1C1;
}
body::-webkit-scrollbar-thumb:hover { background-color: #A8A8A8;  }
body::-webkit-scrollbar-thumb:active { background-color: #787878; }

.ant-form-item { margin-bottom: 30px; }
.ant-form-item-explain, .ant-form-item-extra { margin-top: 6px; line-height: 100%; }
.ant-dropdown a.active { background: #daedff; }

/*@import url('https://fonts.googleapis.com/css2?family=Balsamiq+Sans:wght@400;700&display=swap');*/
/*@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;800&display=swap');*/
/*font-family: 'Poppins', sans-serif;*/


body {
  background: #f7f8fa;
  color: #000;
  font-size: 16px;
  /*font-family: 'Balsamiq Sans', cursive;*/
  font-family: 'Roboto', sans-serif;
}

.btn-orange {
  background: #F2AF1F;
  border-color: #e6a416;
}
.btn-orange:hover, .btn-orange:focus {
  background: #da9d18;
  border-color: #e6a416;
}
.btn-orange:focus {
  background: #bd8712;
  border-color: #7f5a0a;
}

.btn-orange:disabled, .btn-orange[disabled] {
  background: #999;
  color: #fff;
}


/* form elements focus colors */
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: 0 0 0 2px #F2AF1F;
  border-color: #bd8712;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector { border-color: #bd8712; }

.ant-input-number:focus, .ant-input-number-focused {
  box-shadow: 0 0 0 2px #F2AF1F;
  border-color: #bd8712;
}
.ant-picker-focused, .ant-input:focus, .ant-input-focused,
.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 2px #F2AF1F;
  border-color: #bd8712;
}
.ant-input-number:hover { border-color: #bd8712; }

.ant-picker:hover,
.ant-picker-focused { border-color: #bd8712; }

.ant-input:hover { border-color: #bd8712; }
.ant-input-affix-wrapper:hover { border-color: #bd8712; }


input, .ant-input {
  font-size: 18px;
}

.ant-btn { border-radius: 20px; }




/*modal confirm styles*/
.ant-modal-confirm .ant-modal-content {
  background: #fff;
  border-radius: 16px;
}
.ant-modal-confirm .ant-modal-content .ant-modal-confirm-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: #1D1E21;
}

.booking-modal-default.ant-modal-confirm .ant-modal-content .ant-modal-confirm-btns {
  display: flex;
  float: inherit;
  margin-left: 38px;
  align-items: center;
}
.booking-modal-default.ant-modal-confirm .ant-modal-content .ant-modal-confirm-btns button {
  flex: 1 1;
  height: 44px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  border-radius: 32px;
}

.booking-modal-default.ant-modal-confirm .ant-modal-content .ant-modal-confirm-btns button.ant-btn {
  background: #F2AF1F;
  border-color: #F2AF1F;
}
.booking-modal-default.ant-modal-confirm .ant-modal-content .ant-modal-confirm-btns button.ant-btn.ant-btn-default {
  background: #FFF;
  border-color: #F2AF1F;
  color: #F2AF1F;
}


.booking-modal-form.ant-modal-confirm .ant-modal-content .ant-modal-confirm-btns {
  display: flex;
  float: inherit;
  margin-left: 38px;
  align-items: center;
  margin-top: 10px;
}
.booking-modal-form.ant-modal-confirm .ant-modal-content .ant-modal-confirm-btns button {
  flex: 1 1;
  /* height: 44px; */
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  border-radius: 32px;
}

.booking-modal-form.ant-modal-confirm .ant-modal-content .ant-modal-confirm-btns button.ant-btn {
  background: #F2AF1F;
  border-color: #F2AF1F;
}
.booking-modal-form.ant-modal-confirm .ant-modal-content .ant-modal-confirm-btns button.ant-btn.ant-btn-default {
  background: #FFF;
  border-color: #F2AF1F;
  color: #F2AF1F;
}

.property-details-drawer .ant-drawer-content-wrapper {
  width: 750px !important;
}

@media only screen and (max-width: 768px) {
  .property-details-drawer .ant-drawer-content-wrapper { width: 340px !important; }
  .property-guests-drawer .ant-drawer-content-wrapper { width: 340px !important; }
}


@media only screen and (max-width: 768px) {
  .ant-picker-panels {
    flex-direction: column;
    /* margin-top: 80px; */
  }
}

/* custom font for the header */
@font-face {
  font-family: 'edoregular';
  src: url(/static/media/edo-webfont.62bf2cbc.woff2) format('woff2'),
       url(/static/media/edo-webfont.3e859411.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}



