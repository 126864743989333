@import '~antd/dist/antd.css';
@import './reset.css';

/*@import url('https://fonts.googleapis.com/css2?family=Balsamiq+Sans:wght@400;700&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');
/*@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;800&display=swap');*/
/*font-family: 'Poppins', sans-serif;*/


body {
  background: #f7f8fa;
  color: #000;
  font-size: 16px;
  /*font-family: 'Balsamiq Sans', cursive;*/
  font-family: 'Roboto', sans-serif;
}

.btn-orange {
  background: #F2AF1F;
  border-color: #e6a416;
}
.btn-orange:hover, .btn-orange:focus {
  background: #da9d18;
  border-color: #e6a416;
}
.btn-orange:focus {
  background: #bd8712;
  border-color: #7f5a0a;
}

.btn-orange:disabled, .btn-orange[disabled] {
  background: #999;
  color: #fff;
}


/* form elements focus colors */
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: 0 0 0 2px #F2AF1F;
  border-color: #bd8712;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector { border-color: #bd8712; }

.ant-input-number:focus, .ant-input-number-focused {
  box-shadow: 0 0 0 2px #F2AF1F;
  border-color: #bd8712;
}
.ant-picker-focused, .ant-input:focus, .ant-input-focused,
.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 2px #F2AF1F;
  border-color: #bd8712;
}
.ant-input-number:hover { border-color: #bd8712; }

.ant-picker:hover,
.ant-picker-focused { border-color: #bd8712; }

.ant-input:hover { border-color: #bd8712; }
.ant-input-affix-wrapper:hover { border-color: #bd8712; }


input, .ant-input {
  font-size: 18px;
}

.ant-btn { border-radius: 20px; }




/*modal confirm styles*/
.ant-modal-confirm .ant-modal-content {
  background: #fff;
  border-radius: 16px;
}
.ant-modal-confirm .ant-modal-content .ant-modal-confirm-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: #1D1E21;
}

.booking-modal-default.ant-modal-confirm .ant-modal-content .ant-modal-confirm-btns {
  display: flex;
  float: inherit;
  margin-left: 38px;
  align-items: center;
}
.booking-modal-default.ant-modal-confirm .ant-modal-content .ant-modal-confirm-btns button {
  flex: 1;
  height: 44px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  border-radius: 32px;
}

.booking-modal-default.ant-modal-confirm .ant-modal-content .ant-modal-confirm-btns button.ant-btn {
  background: #F2AF1F;
  border-color: #F2AF1F;
}
.booking-modal-default.ant-modal-confirm .ant-modal-content .ant-modal-confirm-btns button.ant-btn.ant-btn-default {
  background: #FFF;
  border-color: #F2AF1F;
  color: #F2AF1F;
}


.booking-modal-form.ant-modal-confirm .ant-modal-content .ant-modal-confirm-btns {
  display: flex;
  float: inherit;
  margin-left: 38px;
  align-items: center;
  margin-top: 10px;
}
.booking-modal-form.ant-modal-confirm .ant-modal-content .ant-modal-confirm-btns button {
  flex: 1;
  /* height: 44px; */
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  border-radius: 32px;
}

.booking-modal-form.ant-modal-confirm .ant-modal-content .ant-modal-confirm-btns button.ant-btn {
  background: #F2AF1F;
  border-color: #F2AF1F;
}
.booking-modal-form.ant-modal-confirm .ant-modal-content .ant-modal-confirm-btns button.ant-btn.ant-btn-default {
  background: #FFF;
  border-color: #F2AF1F;
  color: #F2AF1F;
}

.property-details-drawer .ant-drawer-content-wrapper {
  width: 750px !important;
}

@media only screen and (max-width: 768px) {
  .property-details-drawer .ant-drawer-content-wrapper { width: 340px !important; }
  .property-guests-drawer .ant-drawer-content-wrapper { width: 340px !important; }
}


@media only screen and (max-width: 768px) {
  .ant-picker-panels {
    flex-direction: column;
    /* margin-top: 80px; */
  }
}

/* custom font for the header */
@font-face {
  font-family: 'edoregular';
  src: url('../../assets/fonts/edo/edo-webfont.woff2') format('woff2'),
       url('../../assets/fonts/edo/edo-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


